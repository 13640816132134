<template>
  <div
    class="relative flex flex-1 flex-col bg-cover bg-fixed bg-no-repeat px-2 pb-3 sm:px-4 lg:px-3"
    style="background-image: url('https://img.freepik.com/free-photo/ultra-detailed-nebula-abstract-wallpaper-4_1562-749.jpg')"
  >
    <SiteHeader v-if="!isHome" />
    <main class="flex flex-1 flex-col">
      <slot />
    </main>
    <SiteFooter />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"

const store = useStore()
const { trackingConsentBannerVisible, announcement } = storeToRefs(store)

const route = useRoute()
const isHome = computed(() => route.name == "index")
</script>

  <style scoped>
  /* :deep(main a:visited) {
     @apply text-amber-700
  }

  :deep(main a:visited:hover) {
     @apply text-amber-700
  } */
  </style>
